@tailwind base;
@tailwind components;
@tailwind utilities;

.error {
    font-size: 14px;
    color: #fd6c6c;
}

.img-container {
    display: flex;
    flex-flow: row wrap;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: .5rem .5rem 0 0;
    padding: 1rem;
    max-width: 150px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .26);
    justify-content: space-between;
    align-items: center;
}

.card img {
    width: 120px;
    height: 100px;
    object-fit: cover;
}

@media screen and (max-width: 400px) {
   .img-container {
       flex-flow: column;
   }
}
